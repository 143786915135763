<template>
  <div class="activity">
    <div class="contents-pc">
      <img width="100%" height="auto" :src="`https://esim.qn.triproaming.cn/banner/limited_${$i18n.locale}.jpg`" alt="">
      <div class="contents-bg" :style="{backgroundImage:`url('https://esim.qn.triproaming.cn/banner/activity_${$i18n.locale}.jpg')`}">
        <div class="container">
          <div class="text" v-html="info.description">
          </div>
          <div class="flex">
            <van-button @click="backToHome" class="button" round size="large" color="#DA3D4D">
            {{ $t('invite.now') }}
            </van-button>
          </div>
          <div class="rule" @click="ruleHandle">{{ $t('invite.rules') }}</div>
        </div>
      </div>
    </div>
    <div class="contents">
      <van-image  fit="contain" :src="`https://esim.qn.triproaming.cn/banner/limited_${$i18n.locale}_xs.jpg`"/>
      <div class="explain">
        <div class="title flex-center-between">
          <span>{{info.title}}</span>
          <span class="rules" @click="ruleHandle">{{ $t('invite.rules') }}</span>
        </div>
        <div class="text" v-html="info.description"></div>
        <van-button round @click="backToHome" size="large" color="linear-gradient(358deg, #773DE1 0%, #893CDB 33%, #E538BC 100%)">
          {{ $t('invite.now') }}
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { GetActInfoAPI } from '../api/api'
export default {
  data() {
    return {
      info:{
        title:'',
        rule:''
      },
    }
  },
  computed: {
    ...mapState(['isApp']),
  },
  mounted() {
   this.init()
  },
  methods: {
    async init(){
      const { act_id } = this.$route.query
      const { data:{ act_info}} = await GetActInfoAPI({type:act_id})
      this.info = act_info;
      document.title = act_info.title;
    },
    ruleHandle(){
      const { act_id } = this.$route.query
      this.$router.push({ name: 'ActivityRules',query:{act_id}});
    },
    backToHome(){
      if(this.isApp == "ios"){
        window.webkit.messageHandlers.backToHomePage.postMessage({});
      }else{
        this.$router.push({ name: 'Home'});
      }
    },
  },
}
</script>
<style lang="scss">
.activity{
  .contents-pc{
    display: block;
    .contents-bg{
      height: 600px;
      background-image: url("https://esim.qn.triproaming.cn/banner/activity_cn.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 300px;
      display: flex;
      justify-content: center;
      .text{
        width: 450px;
        margin: 0 auto;
        text-align: center;
        color: #DA3D4D;
        font-size: 24px;
        margin-bottom: 30px;
      }
      .button{
        width: 250px;
        font-size: 28px;
      }
      .rule{
        margin-top: 10px;
        color: #DA3D4D;
        font-size: 14px;
        text-align: center;
        text-decoration:underline;
        cursor: pointer;
      }
    }
  }
  .contents{
    display: none;
  }
}
@media(max-width:768px){
  .activity{
    min-height:100vh;
    background-color: #F3F3F3;
    .contents-pc{
      display: none;
    }
    .contents{
      display: block;
      .explain{
        padding: 20px 24px;
        .title{
          color: #222222;
          font-size: 16px;
          font-weight: bold;
          .rules{
            color: #5D5D5D;
            font-size: 14px;
            text-decoration:underline;

          }
        }
        .text{
          margin: 20px 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>